define("ember-cli-loaders/templates/components/loader-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GNp44NWZ",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"divs\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[12],[13]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ember-cli-loaders/templates/components/loader-base.hbs"
    }
  });

  _exports.default = _default;
});