define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["ru", {
    "about": {
      "title": "О компании"
    },
    "blog": {
      "desc": "Все что нужно для офиса от бумаги А4 до ручки и карандаша. Вся информация о канцелярских товарах в Гомеле по доступной цене.",
      "title": "Все что вы хотели знать о канцелярских товарах в Гомеле"
    },
    "cart": {
      "add": "Добавить в корзину",
      "empty": "Ваша корзина пуста",
      "hidden": "недоступен для заказа",
      "hiddenAttantion": {
        "end": "не доступны для добавления в корзину!",
        "icon": "x",
        "start": "Tовары с признаком"
      },
      "item": {
        "added": "Продукт добавлен в корзину",
        "error": "Ошибка при добавлении продукта в корзину",
        "quantityUpdated": "Колличество продукта в корзине изменено",
        "removed": "Продукт удален из корзины"
      },
      "leaveComment": "комментарий к заказу",
      "loading": "Загрузка корзины",
      "order": "оформить заказ",
      "price": "Цена без НДС:",
      "priceTotal": "Сумма с НДС",
      "priceTotalWtax": "Сумма без НДС",
      "priceWtax": "Цена с НДС:",
      "remove": "Удалить из корзины",
      "title": "Корзина",
      "totalSum": "Итого с НДС"
    },
    "catalog": {
      "categories": {
        "productsFromCat": "Товары из категории",
        "saleProducts": "Обратите внимание на список товаров по сниженным ценам из этого раздела",
        "title": "Категории"
      },
      "desc": "Купить канцелярские товары с максимальной выгодой для вашего кошелька. Убедись позвонив.",
      "h1": "Магазин канцтоваров для офиса",
      "products": {
        "availability": {
          "expected": "Ожидается поступление",
          "onOrder": "под заказ",
          "order": "Под заказ в течении 5-7 дней",
          "orderDays": "в течение 5-7 дней",
          "outStock": "Уточняйте наличие",
          "stock": "В наличии"
        },
        "code": "Код",
        "count": "Кол-во",
        "inOrder": "Заказано",
        "information": "Информация о товаре",
        "noItems": "Извините, ничего не найдено",
        "noPrice": "уточните по телефону",
        "price": "Цена без НДС",
        "priceWtax": "Цена с НДС",
        "products-list": {
          "views": {
            "list": "Обычный",
            "module": "Плитка",
            "stream": "Прайс лист"
          }
        },
        "sale": "Распродажа",
        "stock": "Новинка!",
        "stockProducts": "Акционные товары",
        "title": "Товары",
        "unit": "Единица измерения"
      },
      "rubrics": {
        "byOrder": "Под заказ",
        "description": "Описание",
        "saleProducts": "Обратите внимание на список товаров по сниженным ценам из этого раздела",
        "title": "Рубрики"
      },
      "seoTitle": "Купить канцелярские товары, бумагу, технику и аксессуары для офиса",
      "sort": "Сортировка",
      "sortByName": "по алфавиту",
      "sortByNameAsc": "А-я",
      "sortByNameDesc": "Я-а",
      "sortByPrice": "по цене",
      "sortByPriceAsc": "Дешёвые",
      "sortByPriceDesc": "Дорогие",
      "sortDefault": "по умолчанию",
      "subCategories": {
        "title": ""
      },
      "title": "Каталог"
    },
    "common": {
      "auth": "Авторизация",
      "cancel": "отмена",
      "code": "Код",
      "desc": "Выгодные цены на канцелярские товары порадуют самых экономных покупателей. Позвони и проверь",
      "download": "скачать",
      "forgot": "Восстановить/изменить пароль",
      "getNewMail": "Получить новую",
      "home": "Главная",
      "homeH1": "Офисные канцтовары",
      "homeTitle": "Товары для офиса, магазин канцтоваров для юр.лиц, доставка по Гомелю",
      "keywords": "канцелярский товар, магазин канцтоваров, купить канцтовары, канцелярия магазин, канцтовары интернет, магазин канцтоваров в гомеле, опт канцтовары, канцтовары каталог, дешевый канцтовары, адреса магазинов канцтоваров, карандаш магазин канцтоваров, офис канцтовары",
      "login": "Войти",
      "mailFailure": "Ссылка не активна!",
      "next": "Далее",
      "notFound": "Страница не найдена 404",
      "notFoundText": "Страница, которую вы запросили, не найдена, возможно, она удалена, переименована или временно недоступна.",
      "notFoundTitle": "Ошибка 404",
      "repair": "Изменить пароль",
      "repairPasswordTitle": "Восстановление пароля",
      "reviews": "Отзывы",
      "rub": "Руб.",
      "save": "Сохранить",
      "seeLess": "Скрыть",
      "seeMore": "Показать полностью",
      "title": " Название",
      "unit": "штук",
      "unlock": "Разблокировать профиль",
      "unlocked": "Ссылка для разблокировки отправлена на емэил"
    },
    "contacts": {
      "desc": "Пожалуй лучший по соотношению цена и качество магазин товаров для офиса в Гомеле. Звони и удивляйся. Приходи и заказывай.",
      "h1": "Магазин товаров для офиса в Гомеле",
      "seoTitle": "ООО 'Бумажная гильдия': Канцелярия и хозтовары для офиса",
      "title": "Контакты"
    },
    "entities": {
      "category": "",
      "product": "",
      "rubric": "",
      "subCategory": ""
    },
    "errors": {
      "Invalid_login_or_password._Attempts_left_=_3.": "Был введён неправильный пароль. Для защиты ваших данных вход на сайт блокируется после 5-го ввода неправильного пароля. Осталось попыток: 3",
      "Invalid_password._Attempts_left_=_1.": "Был введён неправильный пароль. Для защиты ваших данных вход на сайт блокируется после 5-го ввода неправильного пароля. Осталось попыток: 1",
      "Invalid_password._Attempts_left_=_2.": "Был введён неправильный пароль. Для защиты ваших данных вход на сайт блокируется после 5-го ввода неправильного пароля. Осталось попыток: 2",
      "Invalid_password._Attempts_left_=_3.": "Был введён неправильный пароль. Для защиты ваших данных вход на сайт блокируется после 5-го ввода неправильного пароля. Осталось попыток: 3",
      "Invalid_password._Attempts_left_=_4.": "Был введён неправильный пароль. Для защиты ваших данных вход на сайт блокируется после 5-го ввода неправильного пароля. Осталось попыток: 4",
      "Your_account_is_locked.": "Вход на сайт для пользователя заблокирован, т.к. система зарегистрировала пять попыток подбора пароля.",
      "Your_account_is_locked._Contact_tech_support.": "Вход на сайт для пользователя заблокирован, т.к. система зарегистрировала пять попыток подбора пароля.",
      "default": "Извините, что-то пошло не так. Попробуйте обновить страницу.",
      "invalid_grant": "Введённый адрес почты не зарегистрирован в системе. Введите правильный адрес для входа или зарегистрируйтесь."
    },
    "favorite": {
      "guessHelpInfo": "Для добавления в избранное зарегистрируйтесь на сайте.",
      "product": {
        "added": "Продукт добавлен в избранные",
        "error": "Ошибка при добавлении продукта в избранные",
        "removed": "Продукт удален из избранных"
      },
      "title": "Избранные",
      "userHelpInfo": {
        "add": "добавить в избранное",
        "remove": "удалить из избранного"
      }
    },
    "files": {
      "title": "Информация"
    },
    "footer": {
      "address": "г.Гомель, ул. Шилова 7",
      "c": "© 2010—2020, БУМАЖНАЯ ГИЛЬДИЯ ООО",
      "unn": "УНН 490855343"
    },
    "header": {
      "attantion": "Внимание! Актуальные цены - при выставлении счета!",
      "logoAlt": "Бумажная гильдия",
      "nav": {
        "aboutCompany": "О компании",
        "catalog": "Каталог",
        "contacts": "Контакты",
        "express": "Быстрый заказ",
        "favorite": "избранное",
        "files": "Информация",
        "login": "Вход",
        "logout": "Выйти",
        "mobile": {
          "code": "+375(29)",
          "numbers": ["306-12-17", "390-12-17"],
          "texts": ["товары для рекламы", "товары для офиса"]
        },
        "paymentAndDelivery": "Оплата и доставка",
        "personal": "личный кабинет",
        "phone": {
          "code": "+375(232)",
          "numbers": ["22-12-10", "22-12-16"]
        },
        "regestration": "Регистрация"
      },
      "onlyLegal": "Сайт только для ИП и юридических лиц",
      "search": {
        "minSymbolCountText": "Необходимо ввести минимум три символа",
        "noResultText": "К сожалению, наш поисковый механизм не смог найти товары соответствующие фразе «{searchValue}». Попробуйте другую комбинацию.",
        "placeholder": "Введите название продукта или артикул для поиска..."
      },
      "title": "Бумажная гильдия",
      "workingHours": "будни 9:00 - 16:00",
      "workingInfo": "Часы работы:"
    },
    "order": {
      "addCartItems": "в корзине имеются отобранные товары. Прикрепить их к Быстрому заказу?",
      "address": "Адрес доставки",
      "cancel": "Отмена",
      "checks": "Полученные счета-фактуры",
      "confirm": "Отправить заявку",
      "contact": "Контактное лицо",
      "delivery": {
        "check": "требует уточнения",
        "date": "дата доставки",
        "info": "Условие доставки",
        "self": "самовывоз"
      },
      "emptyHistory": "История заказов пуста",
      "no": "нет",
      "noAuth": "Оформление заказа доступно только авторизованным пользователям.",
      "number": "Номер завки: ",
      "order": "Заказ",
      "req": "Поле должно быть заполнено!",
      "require": {
        "address": "В ваших данных заполнено более одного адреса доставки. Выберите один.",
        "contact": "В ваших данных заполнено более одного пользователя для связи. Выберите один.",
        "default": "Поле не заполнено!"
      },
      "rub": "руб.",
      "sent": "Ваш заказ успешно отправлен. Статус обработки заказа можно отследить на закладке 'История заказов' в личном кабинете.Сотрудник ООО 'Бумажная гильдия' свяжется с вами для подтверждения вашего заказа.",
      "status": "Статус заказа",
      "sum": "на сумму",
      "tax": "с НДС",
      "title": "Быстрый заказ",
      "toAuth": "Войти на сайт",
      "toReg": "Перейти к регистрации",
      "userLimit": "Превышен лимит создания заказов за день от одного пользователя",
      "userPermissionDenied": "Для вас заблокирована возможность отправки заказов. Для разблокировки  -  свяжитесь с Администрацией сайта.",
      "yes": "да"
    },
    "payment": {
      "title": "Oплата и доставка"
    },
    "personal": {
      "address": {
        "name": "адрес"
      },
      "data": {
        "manager": "Ваш персональный менеджер",
        "update": "Обновить",
        "updated": "Данные успешно обновлены"
      },
      "hystory": {
        "copyProducts": "добавить товары из этого заказа в корзину",
        "showProducts": "продукты заказа"
      },
      "tabs": {
        "addresses": "Адреса доставок",
        "data": "Данные профиля",
        "hystory": "История заказов",
        "users": "Данные пользователей"
      },
      "title": "Личный кабинет",
      "users": {
        "add": "Добавить",
        "text": "Если заявки через этот кабинет могут делать несколько пользователей –рекомендуем вам внести их данные, чтобы при отправке заказа выбрать форму обратной связи.Это позволит сократить время обработки ваших заказов и избежать путаницы"
      }
    },
    "registration": {
      "attantion": "ВНИМАНИЕ! Мы работаем только с ИП и юридическими лицами!",
      "checkMail": "Письмо с ссылкой на регистрацию отправлено вам на почту.",
      "desc": "Введите вашу почту для получения ссылки на регистрацию.",
      "faild": "Ошибка регистрации, возможно пользователь с такой почтой уже зарегестрирован на сайте.",
      "form": {
        "company": "Название организации",
        "confirm": "Зарегистрироваться",
        "email": "Емайл",
        "lastName": "Фамилия",
        "minLength": "Пароль должен состоять минимум из 6 символов",
        "minNine": "Длина поля должна состоять минимум из 9 символов",
        "name": "Имя / Отчество",
        "notMatch": "Пароли не совпадают",
        "password": "Пароль",
        "phone": "Телефон (+375)",
        "phoneIncorrect": "Телефон заполнен некорректно!",
        "repeatPassword": "Повторите пароль",
        "require": "Поле не заполнено!",
        "unp": "УНП",
        "unpError": "Не верный номер УНП"
      },
      "getEmail": "Получить ссылку",
      "notFound": "Полъзователь с такой почтой не найден в системе.",
      "oneMore": " повторно",
      "passResetFaild": "Ошибка при восстановлении пароля! Попробуйте повторить попытку.",
      "passResetSuccess": "Пароль успешно востановлен!",
      "passResetTitle": "Восстановление пароля",
      "repairCheckMail": "Ссылка для изменения пароля отправлена вам на почту.",
      "repairDesc": "Введите вашу почту для получения ссылки на восстановление пароля.",
      "success": "Вы успешно зарегистрировались на сайте!",
      "title": "Регистрация",
      "userExist": "Пользователь с такой почтой уже зарегестрирован на сайте."
    },
    "statuses": {
      "new": "Новый"
    }
  }]];
  _exports.default = _default;
});