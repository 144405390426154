define("ember-cli-loaders/components/loader-ball-clip-rotate-pulse", ["exports", "ember-cli-loaders/components/loader-base"], function (_exports, _loaderBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _loaderBase.default.extend({
    classNames: ['ball-clip-rotate-pulse'],
    divCount: 2
  });

  _exports.default = _default;
});