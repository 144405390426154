define("ember-cli-loaders/components/loader-line-scale-party", ["exports", "ember-cli-loaders/components/loader-base"], function (_exports, _loaderBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _loaderBase.default.extend({
    classNames: ['line-scale-party'],
    divCount: 4
  });

  _exports.default = _default;
});