define("ember-cli-loaders/components/loader-base", ["exports", "ember-cli-loaders/templates/components/loader-base"], function (_exports, _loaderBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _loaderBase.default,
    classNames: ['loader-base'],
    divCount: null,
    divs: Ember.computed('divCount', function () {
      var divCount = this.divCount;
      var divs = [];

      if (Ember.typeOf(divCount) !== 'number') {
        return divs;
      }

      for (var index = 0; index < divCount; index++) {
        divs.push(index);
      }

      return divs;
    })
  });

  _exports.default = _default;
});