define("ember-cli-tailwind/utils/classes-for-module-style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  var moduleStyles = {
    'border-radius': function borderRadius(moduleStyle) {
      var name = moduleStyle.name;
      var postfix = name === 'default' ? '' : "-".concat(name);
      return ["rounded".concat(postfix), "rounded-t".concat(postfix), "rounded-r".concat(postfix), "rounded-b".concat(postfix), "rounded-l".concat(postfix), "rounded-tl".concat(postfix), "rounded-tr".concat(postfix), "rounded-br".concat(postfix), "rounded-bl".concat(postfix)];
    },
    'border-widths': function borderWidths(moduleStyle) {
      var name = moduleStyle.name;
      var postfix = name === 'default' ? '' : "-".concat(name);
      return ["border".concat(postfix), "border-t".concat(postfix), "border-r".concat(postfix), "border-b".concat(postfix), "border-l".concat(postfix)];
    },
    'colors': function colors(moduleStyle) {
      var name = moduleStyle.name;
      return ["text-".concat(name), "bg-".concat(name), "border-".concat(name)];
    },
    'font-weights': function fontWeights(moduleStyle) {
      var name = moduleStyle.name;
      return ["font-".concat(name)];
    },
    'height': function height(moduleStyle) {
      var name = moduleStyle.name;
      return ["h-".concat(name)];
    },
    'letter-spacing': function letterSpacing(moduleStyle) {
      var name = moduleStyle.name;
      return ["tracking-".concat(name)];
    },
    'line-height': function lineHeight(moduleStyle) {
      var name = moduleStyle.name;
      return ["leading-".concat(name)];
    },
    'margin': function margin(moduleStyle) {
      var name = moduleStyle.name;
      return ["m-".concat(name), "mt-".concat(name), "mr-".concat(name), "mb-".concat(name), "ml-".concat(name), "mx-".concat(name), "my-".concat(name)];
    },
    'max-height': function maxHeight(moduleStyle) {
      var name = moduleStyle.name;
      return ["max-h-".concat(name)];
    },
    'max-width': function maxWidth(moduleStyle) {
      var name = moduleStyle.name;
      return ["max-w-".concat(name)];
    },
    'min-height': function minHeight(moduleStyle) {
      var name = moduleStyle.name;
      return ["min-h-".concat(name)];
    },
    'min-width': function minWidth(moduleStyle) {
      var name = moduleStyle.name;
      return ["min-w-".concat(name)];
    },
    'negative-margin': function negativeMargin(moduleStyle) {
      var name = moduleStyle.name;
      return ["-m-".concat(name), "-mt-".concat(name), "-mr-".concat(name), "-mb-".concat(name), "-ml-".concat(name), "-mx-".concat(name), "-my-".concat(name)];
    },
    'opacity': function opacity(moduleStyle) {
      var name = moduleStyle.name;
      return ["opacity-".concat(name)];
    },
    'padding': function padding(moduleStyle) {
      var name = moduleStyle.name;
      return ["p-".concat(name), "pt-".concat(name), "pr-".concat(name), "pb-".concat(name), "pl-".concat(name), "px-".concat(name), "py-".concat(name)];
    },
    'shadows': function shadows(moduleStyle) {
      var name = moduleStyle.name;
      var postfix = name === 'default' ? '' : "-".concat(name);
      return ["shadow".concat(postfix)];
    },
    'svg-fill': function svgFill(moduleStyle) {
      var name = moduleStyle.name;
      return ["fill-".concat(name)];
    },
    'svg-stroke': function svgStroke(moduleStyle) {
      var name = moduleStyle.name;
      return ["stroke-".concat(name)];
    },
    'text-sizes': function textSizes(moduleStyle) {
      var name = moduleStyle.name;
      return ["text-".concat(name)];
    },
    'width': function width(moduleStyle) {
      var name = moduleStyle.name;
      return ["w-".concat(name)];
    },
    'z-index': function zIndex(moduleStyle) {
      var name = moduleStyle.name;
      return ["z-".concat(name)];
    }
  };

  function _default(moduleStyle) {
    return moduleStyles[moduleStyle.module](moduleStyle);
  }
});